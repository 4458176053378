<template>
  <div class="pb-12">
    <div class="flex justify-start items-end">
      <div class="md:w-6/12"></div>
      <div class="md:w-5/12">
        <HeaderGlobal
          :titlemenu1="$t('account_dropdown_menu_pet_records')"
          :to1="'/petoverview/new'"
          :titlemenu2="this.title2"
          :to2="'/petrecord'"
          :titlemenu3="$t('pet_dropdown_menu_recent_activity')"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex justify-center items-center">
      <RecentTable :pet="this.$route.params.data" />
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../header/HeaderGlobal.vue";
import RecentTable from "./RecentTable.vue";

export default {
  components: {
    HeaderGlobal,
    RecentTable,
  },
  data() {
    return {
      pet: {},
      title2: "",
    };
  },
  created() {
    var retrievedObject = localStorage.getItem("pet");

    this.pet = JSON.parse(retrievedObject);

    this.title2 =
      this.pet.species == 1
        ? this.$t("global_dog") + " Record: " + this.pet.callName
        : this.pet.species == 2
        ? this.$t("global_cat") + " Record: " + this.pet.callName
        : this.pet.species == 3
        ? this.$t("global_ferret") + " Record: " + this.pet.callName
        : this.pet.species == 4
        ? this.$t("global_bird") + " Record: " + this.pet.callName
        : this.$t("global_other") + " Record: " + this.pet.callName;
  },
  methods: {},
};
</script>

<style></style>
