<template>
  <div class="my-6">
    <RecentSection />
    <NavMenuSecondary page="recentactivity" />
  </div>
</template>
  
  <script>
import RecentSection from "../components/recentactivity/RecentSection.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue";

export default {
  components: {
    RecentSection,
    NavMenuSecondary,
  },
};
</script>