<template>
  <div class="w-full md:w-11/12 lg:w-10/12">
    <div class="flex items-center">
      <img
        rel="prefetch"
        class="rounded-full w-24 h-24"
        :src="pet.profile_picture"
        :alt="pet.callName"
      />

      <h1 class="pl-4 text-3xl font-proximaMedium font-bold">
        {{ this.pet.callName }}
      </h1>
    </div>
    <div class="w-1000 md:w-full">
      <div
        class="
          py-6
          px-4
          mt-4
          bg-primary
          rounded-tl-md rounded-tr-3xl
          font-proximaMedium
          text-white
        "
      >
        <ul class="flex">
          <li class="w-3/12">{{ $t("global_user") }}</li>
          <li class="w-5/12">{{ $t("global_action") }}</li>
          <li class="w-4/12">
            <date-picker
              v-model="time3"
              range
              placeholder="Select datetime range"
              range-separator=" - "
              format="DD MMM, YYYY"
              
              @change="onDateChange()"
              class="w-full rounded-md p-1 border-2 border-primary my-1 text-sm"
            ></date-picker>
          </li>
        </ul>
      </div>
      <div class="bg-white h-96 overflow-auto">
        <div v-for="activity in activities" :key="activity.id">
          <RecentTableEach :activity="activity" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RecentTableEach from "./RecentTableEach.vue";
import AddIcon from "../icons/AddIcon.vue";
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import InputRadioEach from "../forms/InputRadioEach.vue";
import InputRadioText from "../forms/InputRadioText.vue";
import MicIcon from "../icons/MicIcon.vue";
import VideoIcon from "../icons/VideoIcon.vue";
import FormError from "../warning/FormError.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

import { getPetRecordHistory } from "../../services/petRecordService";

export default {
  components: {
    RecentTableEach,
    AddIcon,
    SubmitButton,
    ModalsConfirm,
    BorderedButton,
    InputRadioEach,
    InputRadioText,
    MicIcon,
    VideoIcon,
    FormError,
    DatePicker,
  },
  data() {
    return {
      pet:{},
      addActivityModals: false,
      addVideoModals: false,
      addVoiceModals: false,
      addNewVideoModals: false,
      addNewVoiceModals: false,
      componentKey: 0,
      title: "",
      description: "",
      frequency: "",
      custom_frequency: "",
      highlightedEmoji: "",
      //selectedEmoji: "",
      newVideo: "",
      newVoice: "",
      newVideoDetails: {},
      newVoiceDetails: {},
      errors: {},
      time3: [],
      activities: [],
      activitiesAll: [],
    };
  },
  
  computed: {},
  async created() {
    var retrievedObject = localStorage.getItem("pet");

    this.pet = JSON.parse(retrievedObject);

    const acts = await getPetRecordHistory(this.pet.petID);
    this.activities = acts.data;
    this.activitiesAll = acts.data;
  },

  methods: {
    onDateChange() {
      this.activities = this.activitiesAll;
      console.log(
        moment(String(this.activities[0].history_date)).format("DD/MM/YYYY") >=
          this.time3[0],
        moment(String(this.activities[0].history_date)).format("DD/MM/YYYY"),
        this.time3[0]
      );
      const vals = [];
      this.activities.map((item) => {
        var date1 = moment(item.history_date).format("dd-mm-yyyy");
        var date2 = moment(this.time3[0]).format("dd-mm-yyyy");
        var date3 = moment(this.time3[1]).format("dd-mm-yyyy");
        console.log(date1, date2);
        if (date1 >= date2 && date1 <= date3) {
          vals.push(item);
        }
      });

      this.activities = vals;
      console.log(this.activities);
    },
  },
};
</script>

<style scoped>
textarea {
  resize: none;
}
.mx-datepicker {
  width: 100%;
}

.mx-datepicker-popup {
  z-index: 9999;
}
</style>
