<template>
  <div>
    <ul class="flex font-proximaLight border-r border-l border-lightGrey20">
      <li class="border-r border-b border-lightGrey p-4 w-3/12 flex">
        <span>{{ user }}</span>
      </li>
      <li class="border-r border-b border-lightGrey p-4 w-5/12 flex">
        <span>{{
          $t(field) + ' was changed from "' + old + '"  to "' + newAct + '".'
        }}</span>
      </li>
      <li
        class="
          border-r border-b border-lightGrey
          p-4
          w-2/12
          flex
          items-center
          justify-center
        "
      >
        <span>{{ history_date }} </span>
      </li>
      <li
        class="
          border-r border-b border-lightGrey
          p-4
          w-2/12
          flex
          items-center
          justify-center
        "
      >
        <span>{{ history_time }} </span>
      </li>
    </ul>
  </div>
</template>

<script>
import TrashIcon from "../icons/TrashIcon.vue";
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import InputRadioEach from "../forms/InputRadioEach.vue";
import InputRadioText from "../forms/InputRadioText.vue";
import MicIcon from "../icons/MicIcon.vue";
import VideoIcon from "../icons/VideoIcon.vue";
import PaperclipIcon from "../icons/PaperclipIcon.vue";

export default {
  components: {
    TrashIcon,
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    InputRadioEach,
    InputRadioText,
    MicIcon,
    VideoIcon,
    PaperclipIcon,
    TrashIcon,
  },
  props: ["activity"],
  data() {
    return {
      deleteActModals: false,
      editActivityModals: false,
      addVideoModals: false,
      addVoiceModals: false,

      componentKey: 0,
      user: this.activity.user,
      field: this.activity.field,
      newAct: this.activity.new,
      old: this.activity.old,
      history_date: this.activity.history_date,
      history_time: this.activity.history_time,

      custom_frequency: this.activity.custom_frequency,
      highlightedEmoji: "",
      selectedEmoji: "",
      newVideo: "",
      newVoice: "",
      newVideoDetails: {},
      newVoiceDetails: {},
      emojis: [],
    };
  },
  /* watch: {
    timeRange: function () {
      this.filterListByTime(this.timeRange)
    },
  }, */

  methods: {},
};
</script>

<style scoped>
textarea {
  resize: none;
}
</style>